import "./site.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import React, { useState, useEffect } from "react";
import AdminNav from "./nav";
import { useLocation, useNavigate } from 'react-router-dom';

function Site() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const siteId = queryParams.get('siteid');
  const usertype = queryParams.get('usertype');
  const userId = localStorage.getItem('userid');
  const username = localStorage.getItem('username');

  const now = new Date(); // Get current time

  const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
  const istNow = new Date(now.getTime() + istOffset); // Current time in IST

  // Format to 'YYYY-MM-DDTHH:MM'
  const istNowFormatted = istNow.toISOString().slice(0, 10);

  let formattedDate = istNowFormatted;


  const [Loader, setLoader] = useState(true);
  const [GeneralData, setGeneralData] = useState();
  const [OperatorShiftData, setOperatorShiftData] = useState([]);
  const [EquipmentData, setEquipmentData] = useState([]);
  const [OperationData, setOperationData] = useState([]);
  const [ChemicalData, setChemicalData] = useState([]);
  const [ToolboxData, setToolboxData] = useState([]);
  const [MonitoringData, setMonitoringData] = useState([]);
  const [MsgData, setMsgData] = useState([]);
  const [Water, setWater] = useState([]);
  const [WaterParameter, setWaterParameter] = useState([]);
  const [checkShift, setcheckShift] = useState(false);
  const [InputDate, setInputDate] = useState();

  const [Changed, setChanged] = useState(0);




  useEffect(() => {
    const FetchSiteGeneralData = async () => {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'user/ViewSiteGeneralData.php?siteid=' + siteId);
      const jsonData = await response.json();
      if (jsonData.error) {
        alert("Something went Wrong");
      }
      setGeneralData(jsonData);
      setLoader(false);
    };
    FetchSiteGeneralData();

  }, [siteId]);



  function checkAttendance(startTime, endTime) {
    // Convert time strings to comparable values (HH:MM format)
    const start = startTime.split(':').map(Number);
    const end = endTime.split(':').map(Number);

    // Get the current time in Indian Standard Time (IST)
    const now = new Date();
    const options = { timeZone: 'Asia/Kolkata', hour: '2-digit', minute: '2-digit', hour12: false };
    const liveTime = now.toLocaleTimeString('en-GB', options); // 'en-GB' gives HH:MM format
    const live = liveTime.split(':').map(Number);

    // Convert hours and minutes to total minutes for easy comparison
    const startInMinutes = start[0] * 60 + start[1];
    const endInMinutes = end[0] * 60 + end[1];
    const liveInMinutes = live[0] * 60 + live[1];

    // Check for overnight time range
    if (startInMinutes < endInMinutes) {
      // Normal case: start and end time are on the same day
      return liveInMinutes >= startInMinutes && liveInMinutes <= endInMinutes;
    } else {
      // Overnight case: end time is on the next day
      return liveInMinutes >= startInMinutes || liveInMinutes <= endInMinutes;
    }
  }


  const FetchSiteShiftData = async (date) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteShiftDataOnlyOperator.php?siteid=${siteId}&date=${date}`);
    const jsonData = await response.json();
    if (jsonData.error) {
      alert("Something went Wrong");
    }
    console.log(jsonData)
    setOperatorShiftData(jsonData);

  };

  useEffect(() => {


    const FetchSiteShiftData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteShiftDataOnlyOperator.php?siteid=${siteId}&date=${formattedDate}`);
      const jsonData = await response.json();
      if (jsonData.error) {
        alert("Something went Wrong");
      }
      console.log(jsonData)
      setOperatorShiftData(jsonData);

      if (jsonData.length > 0) {
        jsonData.map((shift) => {
          if (shift.employeename === username) {
            formattedDate = shift.date;
            setcheckShift(checkAttendance(shift.startingtime, shift.endingtime));
          }
          return;
        });
      }

    };
    FetchSiteShiftData();

    const FetchSiteEquipmentData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteEquipmentData.php?siteid=${siteId}&userid=${userId}&date=${formattedDate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setEquipmentData(data);
    };
    FetchSiteEquipmentData();

    const FetchSiteOperationData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteOperationData.php?siteid=${siteId}&userid=${userId}&date=${formattedDate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setOperationData(data);
    };
    FetchSiteOperationData();


    const FetchSiteChemicalData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteChemicalData.php?siteid=${siteId}&userid=${userId}&date=${formattedDate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setChemicalData(data);
    };
    FetchSiteChemicalData();


    const FetchSiteToolboxData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteToolboxData.php?siteid=${siteId}&userid=${userId}&date=${formattedDate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setToolboxData(data);
    };

    FetchSiteToolboxData();


    const FetchSiteWaterData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteWaterData.php?siteid=${siteId}&userid=${userId}&date=${formattedDate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setWater(data);
    };

    FetchSiteWaterData();

    const FetchSiteWaterParameterData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteWaterParameterData.php?siteid=${siteId}&userid=${userId}&date=${formattedDate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setWaterParameter(data);
    };

    FetchSiteWaterParameterData();


    const FetchSiteMonitoringData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteMonitoringData.php?siteid=${siteId}&userid=${userId}&date=${formattedDate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setMonitoringData(data);
    };
    FetchSiteMonitoringData();

    const FetchSiteMessageData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteMessageData.php?siteid=${siteId}`); // Replace with your API URL
      const data = await response.json();
      setMsgData(data);
    }
    FetchSiteMessageData();


  }, [siteId, userId, Changed, usertype, formattedDate]);






  const MarkAttendance = async (shiftid, starttime, endTime, img, type) => {


    let attendace = 'Present';


    setLoader(true);
    const url = `${process.env.REACT_APP_BASE_URL}user/add/SiteShift.php?shiftid=${shiftid}&userid=${userId}&time=${istNowFormatted}&type=${attendace}&date=${formattedDate}`; // Replace with your actual URL
    const formData = new FormData();
    formData.append('photo', img);

    try {
      const response = await fetch(url, { method: 'POST', body: formData });
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
      if (data.status) {
        setLoader(false);
        alert("Data Saved");
        setChanged(Changed + 1);
      } else {
        setLoader(false);
        alert("Failed to Save Data");
      }
    } catch (error) {
      setLoader(false);
      alert("Something went wrong");
    }

  };



  const AddEquipmentGet = async (status, equipmentid) => {

    var hours = document.getElementById('ehours' + equipmentid).value;

    setLoader(true);
    const url = `${process.env.REACT_APP_BASE_URL}user/add/SiteEquipment.php?equipmentid=${equipmentid}&userid=${userId}&status=${status}&hours=${hours}&date=${formattedDate}`; // Replace with your actual URL

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
      if (data.status) {
        setLoader(false);
        alert("Data Saved");
        setChanged(Changed + 1);
      } else {
        setLoader(false);
        alert("Failed to Save Data");
      }
    } catch (error) {
      setLoader(false);
      alert("Something went wrong");
    }

  };

  const AddEquipmentPost = async (status, equipmentid, img) => {


    var hours = document.getElementById('ehours' + equipmentid).value;

    setLoader(true);
    const url = `${process.env.REACT_APP_BASE_URL}user/add/SiteEquipment.php?equipmentid=${equipmentid}&userid=${userId}&status=${status}&hours=${hours}&date=${formattedDate}`; // Replace with your actual URL
    const formData = new FormData();
    formData.append('photo', img);

    try {
      const response = await fetch(url, { method: 'POST', body: formData });
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
      if (data.status) {
        setLoader(false);
        alert("Data Saved");
        setChanged(Changed + 1);
      } else {
        setLoader(false);
        alert("Failed to Save Data");
      }
    } catch (error) {
      setLoader(false);
      alert("Something went wrong");
    }

  };


  const [operationTimes, setOperationTimes] = useState({});

  const handleTimeChange = (operationId, timeType, value) => {
    setOperationTimes(prevState => {
      const newState = {
        ...prevState,
        [operationId]: {
          ...prevState[operationId],
          [timeType]: value,
        },
      };
      console.log('Updated operationTimes:', newState);
      return newState;
    });
  };



  const AddOperation = async (operationid) => {
    setLoader(true);

    // Prepare the payload with the operation types and times
    const payload = {
      userid: userId,
      operationid,
      date: formattedDate,
      operationTimes: operationTimes,  // Send the operationTimes as JSON
    };

    const url = `${process.env.REACT_APP_BASE_URL}user/add/SiteOperation.php`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.status) {
        setChanged(prevChanged => prevChanged + 1);
        alert("Data Saved");
      } else {
        alert("Failed to Save Data");
      }
    } catch (error) {
      console.error("Error in AddOperation:", error);
      alert("Something went wrong. Please try again later.");
    } finally {
      setLoader(false);
      setOperationTimes({});
    }
  };





  const Addchemical = async (chemicalid) => {


    setLoader(true);
    var quantiy = document.getElementById(chemicalid + 'quantiy').value;
    var quantiytype = document.getElementById(chemicalid + 'quantiytype').value;

    if (!quantiytype.trim() || !quantiy.trim()) {
      alert('Please fill in all fields');
      setLoader(false);
      return;
    }

    const url = `${process.env.REACT_APP_BASE_URL}user/add/SiteChemical.php?userid=${userId}&chemicalid=${chemicalid}&quantiy=${quantiy}&quantiytype=${quantiytype}&date=${formattedDate}`; // Replace with your actual URL


    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
      if (data.status) {
        setLoader(false);
        alert("Data Saved");
        setChanged(Changed + 1);
      } else {
        setLoader(false);
        alert("Failed to Save Data");
      }
    } catch (error) {
      setLoader(false);
      alert("Something went wrong");
    }

  };


  const AddToolbox = async (toolboxid) => {

    setLoader(true);
    var quantiy = document.getElementById(toolboxid + 'toolboxquantity').value;


    if (!quantiy.trim()) {
      alert('Please fill in all fields');
      setLoader(false);
      return;
    }

    const url = `${process.env.REACT_APP_BASE_URL}user/add/SiteToolbox.php?userid=${userId}&toolboxid=${toolboxid}&quantiy=${quantiy}&date=${formattedDate}`; // Replace with your actual URL 

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
      if (data.status) {
        setLoader(false);
        alert("Data Saved");
        setChanged(Changed + 1);
      } else {
        setLoader(false);
        alert("Failed to Save Data");
      }
    } catch (error) {
      setLoader(false);
      alert("Something went wrong");
    }

  };

  const AddWater = async (water) => {

    setLoader(true);
    var level = document.getElementById(water + 'waterlevel').value;


    if (!level.trim()) {
      alert('Please fill in all fields');
      setLoader(false);
      return;
    }

    const url = `${process.env.REACT_APP_BASE_URL}user/add/SiteWater.php?userid=${userId}&waterid=${water}&siteid=${siteId}&level=${level}&date=${formattedDate}`; // Replace with your actual URL 

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
      if (data.status) {
        setLoader(false);
        alert("Data Saved");
        setChanged(Changed + 1);
      } else {
        setLoader(false);
        alert("Failed to Save Data");
      }
    } catch (error) {
      setLoader(false);
      alert("Something went wrong");
    }

  };

  const AddWaterParamter = async (name, water) => {

    setLoader(true);
    var std = document.getElementById(water + 'waterparameters').value;
    var stdunit = document.getElementById(water + 'waterparametersu').value;
    var value = document.getElementById(water + 'waterparameterv').value;
    var valueunit = document.getElementById(water + 'waterparametersvu').value;


    if (!std.trim()) {
      alert('Please fill in all fields');
      setLoader(false);
      return;
    }
    if (!stdunit.trim()) {
      alert('Please fill in all fields');
      setLoader(false);
      return;
    }
    if (!value.trim()) {
      alert('Please fill in all fields');
      setLoader(false);
      return;
    }
    if (!valueunit.trim()) {
      alert('Please fill in all fields');
      setLoader(false);
      return;
    }

    const url = `${process.env.REACT_APP_BASE_URL}user/add/SiteWaterParamter.php?userid=${userId}&siteid=${siteId}&name=${name}&std=${std}&stdunit=${stdunit}&value=${value}&valueunit=${valueunit}&date=${formattedDate}`; // Replace with your actual URL 

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
      if (data.status) {
        setLoader(false);
        alert("Data Saved");
        setChanged(Changed + 1);
      } else {
        setLoader(false);
        alert("Failed to Save Data");
      }
    } catch (error) {
      setLoader(false);
      alert("Something went wrong");
    }

  };


  const AddMonitoring = async (monitoringid) => {

    setLoader(true);
    var tanklevel = document.getElementById(monitoringid + 'tanklevel').value;


    if (!tanklevel.trim()) {
      alert('Please fill in all fields');
      setLoader(false);
      return;
    }

    const url = `${process.env.REACT_APP_BASE_URL}user/add/SiteMonitoring.php?userid=${userId}&monitoringid=${monitoringid}&tanklevel=${tanklevel}&date=${formattedDate}`; // Replace with your actual URL

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
      if (data.status) {
        setLoader(false);
        alert("Data Saved");
        setChanged(Changed + 1);
      } else {
        setLoader(false);
        alert("Failed to Save Data");
      }
    } catch (error) {
      setLoader(false);
      alert("Something went wrong");
    }

  };

  const AddMonitoringPost = async (monitoringid, img) => {

    setLoader(true);
    var tanklevel = document.getElementById(monitoringid + 'tanklevel').value;


    if (!tanklevel.trim()) {
      alert('Please fill in all fields');
      setLoader(false);
      return;
    }

    const url = `${process.env.REACT_APP_BASE_URL}user/add/SiteMonitoring.php?userid=${userId}&monitoringid=${monitoringid}&tanklevel=${tanklevel}&date=${formattedDate}`; // Replace with your actual URL

    const formData = new FormData();
    formData.append('photo', img);

    try {
      const response = await fetch(url, { method: 'POST', body: formData });
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
      if (data.status) {
        setLoader(false);
        alert("Data Saved");
        setChanged(Changed + 1);
      } else {
        setLoader(false);
        alert("Failed to Save Data");
      }
    } catch (error) {
      setLoader(false);
      console.error('Error saving user data:', error);
    }

  };



  const SendMessage = async () => {

    setLoader(true);
    const from = usertype;
    const to = document.getElementById('to').value;
    const msg = document.getElementById('msg').value;
    if (from.trim() === '') {
      alert('Please enter a from');
      return;
    }
    if (to.trim() === '') {
      alert('Please enter a to');
      return;
    }
    if (msg.trim() === '') {
      alert('Please enter a msg');
      return;
    }
    const url = `${process.env.REACT_APP_BASE_URL}add/admin/SendMessage.php?siteid=${siteId}&from=${from}&to=${to}&msg=${msg}`; // Replace with your actual URL

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
      if (data.status) {
        setLoader(false);
        alert("Data Saved");
        setChanged(Changed + 1);
      } else {
        setLoader(false);
        alert("Failed to Save Data");
      }
    } catch (error) {
      setLoader(false);
      alert("Something went wrong");
    }

  };

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSubmit = () => {
    if (!startDate || !endDate) {
      alert('Please select both start and end dates.');
    } else if (new Date(endDate) < new Date(startDate)) {
      alert('End date must be later than start date.');
    } else {
      // alert(`Start Date: ${startDate}\nEnd Date: ${endDate}`);
      window.location.href = `/#/WeeklyReport?siteid=${siteId}&start_date=${startDate}&end_date=${endDate}`
    }
  };

  const [rows, setRows] = useState([
    { name: 'BOD', std: '', stdunit: 'mqli', value: '', valueunit: 'mqli' },
    { name: 'COD', std: '', stdunit: 'mqli', value: '', valueunit: 'mqli' },
    { name: 'TSS', std: '', stdunit: 'mqli', value: '', valueunit: 'mqli' },
    { name: 'PH', std: '', stdunit: 'mqli', value: '', valueunit: 'mqli' },
    { name: 'TDS', std: '', stdunit: 'mqli', value: '', valueunit: 'mqli' },
    { name: 'MLSS', std: '', stdunit: '%', value: '', valueunit: '%' },
    { name: '', std: '', stdunit: 'mqli', value: '', valueunit: 'mqli' },
    { name: '', std: '', stdunit: 'mqli', value: '', valueunit: 'mqli' },
    { name: '', std: '', stdunit: 'mqli', value: '', valueunit: 'mqli' },
    { name: '', std: '', stdunit: 'mqli', value: '', valueunit: 'mqli' },
  ]);



  const handleChange = (e, rowIndex, key) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][key] = e.target.value;
    setRows(updatedRows);
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();

    // Prepare data for PHP
    const dataToSend = {
      siteid: siteId,
      userid: userId,
      date: formattedDate,
    };

    rows.forEach((row, index) => {
      const suffix = index + 1; // Dynamically map to name1, std1, etc.
      dataToSend[`name${suffix}`] = row.name;
      dataToSend[`std${suffix}`] = row.std;
      dataToSend[`stdunit${suffix}`] = row.stdunit;
      dataToSend[`value${suffix}`] = row.value;
      dataToSend[`valueunit${suffix}`] = row.valueunit;
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/add/SiteWaterParamter.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      const result = await response.json();
      if (result.success) {
        setLoader(false);
        alert("Data Saved");
        setChanged(Changed + 1);
      } else {
        setLoader(false);
        alert("Failed to Save Data");
      }
    } catch (error) {
      setLoader(false);
      console.error('Error saving user data:', error);
    }
  };

  return (
    <div className="site_page">
      <AdminNav />

      {
        Loader ? <div className="loader">
          <img src={'https://i.gifer.com/ZZ5H.gif'} alt="LoaderImage" />
        </div> : null
      }

      <div className="Title2">{GeneralData ? GeneralData.sitename : null}</div>

      <Tabs>
        <TabList className="Tabs">
          <Tab><div className="tab">General</div></Tab>
          <Tab><div className="tab">Reports</div></Tab>
          <Tab><div className="tab">Weekly Report</div></Tab>
          {usertype === "Operator" ? <><Tab><div className="tab">Shift</div></Tab>
            <Tab><div className="tab">Equipments</div></Tab>
            <Tab><div className="tab">Operations</div></Tab>
            <Tab><div className="tab">Chemical</div></Tab>
            <Tab><div className="tab">Toolbox</div></Tab>
            <Tab><div className="tab">Water Parameter</div></Tab>
            <Tab><div className="tab">Monitoring</div></Tab></> : null}
          <Tab><div className="tab">Messages</div></Tab>
        </TabList>


        <TabPanel>

          <div className="general">
            <div className="generaltext">Site Name: {GeneralData ? GeneralData.sitename : null}</div>
            <div className="generaltext">Project Name: {GeneralData ? GeneralData.projectname : null}</div>
            <div className="generaltext">Client Name: {GeneralData ? GeneralData.clientname : null}</div>
            <div className="generaltext">Address: {GeneralData ? GeneralData.address : null}</div>
            <div className="generaltext">Starting Date: {GeneralData ? GeneralData.startingdate : null}</div>
          </div>

        </TabPanel>


        <TabPanel>

          <div className="datetxt">Date</div>
          <input className="dateinput" type="date" onChange={(e) => { setInputDate(e.target.value); FetchSiteShiftData(e.target.value); }} />

          {InputDate ? OperatorShiftData.length > 0 ? (
            <>
              {
                OperatorShiftData.map((operator, key) => (
                  operator.userid ? <button className="shiftbtn" onClick={() => { navigate(`/DailyReport?siteid=${siteId}&userid=${operator.userid}&supervisor=${username}&shiftid=${operator.shiftid}&date=${InputDate}`) }}>{operator.shifttype}</button> : <button style={{ "background": "grey" }} className="shiftbtn" disabled>{operator.shifttype} (not filled)</button>
                ))
              }
            </>
          ) : null : null}


          {OperatorShiftData.length > 0 ? (
            <>
              {OperatorShiftData.map((shift) => (
                <>
                  {
                    <div key={shift.shiftid} className="task">
                      <div className="taskname">{shift.shifttype} - {shift.shiftrole}</div>
                      <div className="taskname">{shift.employeename}</div>
                      <div className="taskname">{shift.startingtime} - {shift.endingtime}</div>
                    </div>
                  }
                </>



              ))}
            </>
          ) : null}

        </TabPanel>


        <TabPanel>

          <div className="datetxt" htmlFor="start-date">Start Date:</div>
          <input
            type="date" className="dateinput"
            id="start-date"
            value={startDate}
            onChange={handleStartDateChange}
          />

          <div className="datetxt" htmlFor="end-date">End Date:</div>
          <input
            type="date" className="dateinput"
            id="end-date"
            value={endDate}
            onChange={handleEndDateChange}
          />

          <button style={{ "display": "block", "margin": "10px auto", "padding": "10px" }} onClick={handleSubmit}>Submit</button>

        </TabPanel>

        {usertype === "Operator" ? <>
          <TabPanel>



            {OperatorShiftData.length > 0 ? (
              <>
                {OperatorShiftData.map((shift) => (
                  <>
                    {
                      shift.employeename === username ? <div key={shift.shiftid} className="task">
                        <div className="taskname">{shift.shifttype} - {shift.shiftrole}</div>
                        <div className="taskname">{shift.employeename}</div>
                        <div className="taskname">{shift.startingtime} - {shift.endingtime}</div>
                        <input id={"shiftinput" + shift.shiftid} style={{ "display": "none" }} accept="image/*" capture="camera" type="file" onChange={(e) => { MarkAttendance(shift.shiftid, shift.startingtime, shift.endingtime, e.target.files[0], checkAttendance(shift.startingtime, shift.endingtime)) }} />
                        {shift.img ? shift.type : checkAttendance(shift.startingtime, shift.endingtime) ? <button onClick={() => { document.getElementById("shiftinput" + shift.shiftid).click() }}>Mark Attendace</button> : "Not Filled"}
                      </div> : null
                    }
                  </>



                ))}
              </>
            ) : null}




          </TabPanel>
          <TabPanel>


            {checkShift ? EquipmentData.length > 0 ? (
              <>
                {
                  EquipmentData.map((equipment) => (
                    <div key={equipment.equipmentid} className="task">
                      <div className="taskname">{equipment.equipmentname}</div>
                      <label>Working Hours</label>
                      {equipment.hours ? <><br /><br />{equipment.hours} Hours<br /><br /></> : <input type="number" min="1" id={"ehours" + equipment.equipmentid} placeholder="Working Hours" />}
                      <input id={"eqimg" + equipment.equipmentid} style={{ "display": "none" }} accept="image/*" capture="camera" type="file" onChange={(e) => { AddEquipmentPost("Maintaince", equipment.equipmentid, e.target.files[0]) }} />
                      {equipment.status ? <div className="taskname">Status: {equipment.status ? equipment.status : "Not Filled"}<br /></div> : (<div className="eqbtns"><button onClick={() => { AddEquipmentGet("On", equipment.equipmentid) }}>On</button><button style={{ "background": "#d64848" }} onClick={() => { AddEquipmentGet("Off", equipment.equipmentid) }}>Off</button><button style={{ "background": "#ed9e23" }} onClick={() => { document.getElementById("eqimg" + equipment.equipmentid).click() }}>Maintance</button></div>)}
                    </div>

                  ))
                }
              </>
            ) : null : <div className="noshift">It's not your shift</div>}


          </TabPanel>
          <TabPanel>


            {checkShift ? OperationData.length > 0 ? (
              <>
                {
                  OperationData.map((operation) => (

                    <div key={operation.operationid} className="task">
                      <div className="taskname">{operation.operationname}</div>


                      {operation.json ? <div>
                        {Object.keys(JSON.parse(operation.json)).map(key => (
                          <div key={key}>
                            <div>{key}</div>           {/* Display the title (key) */}
                            <div>Start time: {JSON.parse(operation.json)[key].start}</div> {/* Display the start time */}
                            <div>End time: {JSON.parse(operation.json)[key].end}</div>   {/* Display the end time */}
                          </div>
                        ))}
                      </div>
                        : <>

                          {JSON.parse(operation.type).map((type, index) => (
                            <div className="opinput">
                              <br></br>
                              <b>{type}</b>
                              <br></br>
                              <label>Start Time</label>
                              <input
                                type="time"
                                value={operationTimes[type]?.start || ''}
                                onChange={(e) => handleTimeChange(type, 'start', e.target.value)}
                              />
                              <label>End Time</label>
                              <input
                                type="time"
                                value={operationTimes[type]?.end || ''}
                                onChange={(e) => handleTimeChange(type, 'end', e.target.value)}
                              />
                            </div>

                          ))}



                          <button onClick={() => { AddOperation(operation.operationid) }}>Add</button>
                        </>}


                    </div>

                  ))}
              </>
            ) : null : <div className="noshift">It's not your shift</div>}



          </TabPanel>
          <TabPanel>



            {checkShift ? ChemicalData.length > 0 ? (
              <>
                {
                  ChemicalData.map((chemical) => (

                    <div key={chemical.chemicalid} className="task">
                      <div className="taskname">{chemical.chemicalname}</div>

                      {chemical.quantity ? <><div className="taskname">Quantity: {chemical.quantity ? chemical.quantity : "Not Filled"} {chemical.quantitytype ? chemical.quantitytype : ""}</div></> : <><div className="cheminput">
                        <label>Quantity:</label>
                        <input id={chemical.chemicalid + "quantiy"} type="text" />
                      </div>
                        <div className="cheminput">
                          <label>Quantity Type:</label>
                          <select id={chemical.chemicalid + "quantiytype"}>
                            <option>Kg</option>
                            <option>L</option>
                          </select>
                          <button onClick={() => { Addchemical(chemical.chemicalid) }}>add</button>
                        </div></>}

                    </div>

                  ))}
              </>
            ) : null : <div className="noshift">It's not your shift</div>}

          </TabPanel>
          <TabPanel>

            {checkShift ? ToolboxData.length > 0 ? (
              <>
                {ToolboxData.map((toolbox) => (

                  <div key={toolbox.toolboxid} className="task">
                    <div className="taskname">{toolbox.name}</div>

                    {toolbox.quantity ? <div className="taskname">Quantity: {toolbox.quantity ? toolbox.quantity : "Not Filled"}</div> : <div className="toolinput">
                      <label>Quantity:</label>
                      <input id={toolbox.toolboxid + "toolboxquantity"} type="text" />
                      <button onClick={() => { AddToolbox(toolbox.toolboxid) }}>Add</button>
                    </div>}

                  </div>

                ))}
              </>
            ) : null : <div className="noshift">It's not your shift</div>}


          </TabPanel>


          <TabPanel>

            {checkShift ? Water.length > 0 ? (
              <>
                {Water.map((parameter) => (

                  <div key={parameter.waterid} className="task">
                    <div className="taskname">{parameter.name}</div>

                    {parameter.level ? <div className="taskname">Quantity: {parameter.level ? parameter.level : "Not Filled"}</div> : <div className="toolinput">
                      <label>Quantity:</label>
                      <input id={parameter.waterid + "waterlevel"} type="text" />
                      <button onClick={() => { AddWater(parameter.waterid) }}>Add</button>
                    </div>
                    }

                  </div>

                ))}




                <div className="task2">
                  <form onSubmit={handleSubmit2}>
                    <table>
                      <tr>
                        <th>name</th>
                        <th>std</th>
                        <th>value</th>
                      </tr>
                      {WaterParameter[0] ?

                        <>
                          <tr>
                            <td>{WaterParameter[0].name1}</td>
                            <td>{WaterParameter[0].std1}{WaterParameter[0].stdunit1}</td>
                            <td>{WaterParameter[0].value1}{WaterParameter[0].valueunit1}</td>
                          </tr>

                          <tr>
                            <td>{WaterParameter[0].name2}</td>
                            <td>{WaterParameter[0].std2}{WaterParameter[0].stdunit2}</td>
                            <td>{WaterParameter[0].value2}{WaterParameter[0].valueunit2}</td>
                          </tr>

                          <tr>
                            <td>{WaterParameter[0].name3}</td>
                            <td>{WaterParameter[0].std3}{WaterParameter[0].stdunit3}</td>
                            <td>{WaterParameter[0].value3}{WaterParameter[0].valueunit3}</td>
                          </tr>

                          <tr>
                            <td>{WaterParameter[0].name4}</td>
                            <td>{WaterParameter[0].std4}{WaterParameter[0].stdunit4}</td>
                            <td>{WaterParameter[0].value4}{WaterParameter[0].valueunit4}</td>
                          </tr>

                          <tr>
                            <td>{WaterParameter[0].name5}</td>
                            <td>{WaterParameter[0].std5}{WaterParameter[0].stdunit5}</td>
                            <td>{WaterParameter[0].value5}{WaterParameter[0].valueunit5}</td>
                          </tr>


                          <tr>
                            <td>{WaterParameter[0].name6}</td>
                            <td>{WaterParameter[0].std6}{WaterParameter[0].stdunit6}</td>
                            <td>{WaterParameter[0].value6}{WaterParameter[0].valueunit6}</td>
                          </tr>

                          <tr>
                            <td>{WaterParameter[0].name7}</td>
                            <td>{WaterParameter[0].std7}{WaterParameter[0].stdunit7}</td>
                            <td>{WaterParameter[0].value7}{WaterParameter[0].valueunit7}</td>
                          </tr>

                          <tr>
                            <td>{WaterParameter[0].name8}</td>
                            <td>{WaterParameter[0].std8}{WaterParameter[0].stdunit8}</td>
                            <td>{WaterParameter[0].value8}{WaterParameter[0].valueunit8}</td>
                          </tr>

                          <tr>
                            <td>{WaterParameter[0].name9}</td>
                            <td>{WaterParameter[0].std9}{WaterParameter[0].stdunit9}</td>
                            <td>{WaterParameter[0].value9}{WaterParameter[0].valueunit9}</td>
                          </tr>

                          <tr>
                            <td>{WaterParameter[0].name10}</td>
                            <td>{WaterParameter[0].std10}{WaterParameter[0].stdunit10}</td>
                            <td>{WaterParameter[0].value10}{WaterParameter[0].valueunit10}</td>
                          </tr>

                        </>

                        :
                        <>

                          {rows.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Name"
                                  value={row.name}
                                  onChange={(e) => handleChange(e, index, 'name')}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Std"
                                  value={row.std}
                                  onChange={(e) => handleChange(e, index, 'std')}
                                />
                                <input
                                  type="text"
                                  placeholder="Std Unit"
                                  value={row.stdunit}
                                  onChange={(e) => handleChange(e, index, 'stdunit')}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Value"
                                  value={row.value}
                                  onChange={(e) => handleChange(e, index, 'value')}
                                />
                                <input
                                  type="text"
                                  placeholder="Value Unit"
                                  value={row.valueunit}
                                  onChange={(e) => handleChange(e, index, 'valueunit')}
                                />
                              </td>
                            </tr>
                          ))}
                        </>
                      }


                    </table>
                    <button type="submit">Add</button>
                  </form>
                </div>





              </>
            ) : null : <div className="noshift">It's not your shift</div>}


          </TabPanel>

          <TabPanel>

            {checkShift ? MonitoringData.length > 0 ? (
              <>
                {MonitoringData.map((monitoring) => (

                  <div key={monitoring.monitoringid} className="task">
                    <div className="taskname">{monitoring.monitoringname}</div>

                    {monitoring.tanklevel ? <div className="taskname">Tank Level: {monitoring.tanklevel ? monitoring.tanklevel : "Not Filled"}</div> : <div className="moninput">
                      <label>Tank Level:</label>
                      <input id={monitoring.monitoringid + "tanklevel"} type="text" />
                      <input id={"monitoringimg" + monitoring.monitoringid} style={{ "display": "none" }} accept="image/*" capture="camera" type="file" onChange={(e) => { AddMonitoringPost(monitoring.monitoringid, e.target.files[0]) }} />

                      {monitoring.img === "false" ? <button onClick={() => { AddMonitoring(monitoring.monitoringid) }}>Add</button> : <button onClick={() => { document.getElementById("monitoringimg" + monitoring.monitoringid).click() }}>Upload</button>}

                    </div>}

                  </div>

                ))}

              </>
            ) : null : <div className="noshift">It's not your shift</div>}



          </TabPanel></> : null}


        <TabPanel>


          {MsgData.length > 0 ? (
            <>
              {MsgData.map((msg) => (

                <div className="message">
                  <div className="name">From: {msg.msgfrom}</div>
                  <div className="name">To: {msg.msgto}</div>
                  <div className="msg">Message: {msg.msg}</div>
                </div>

              ))}
            </>
          ) : null}


          <div className="messageinput">
            <div className="nameinput">To: <select id="to">
              {usertype === "Operator" ? <><option>Supervisor</option>
                <option>Admin</option></> : <><option>Admin</option>
                <option>Operator</option></>}

            </select></div>
            <div className="msginput">Message: <textarea id="msg"></textarea></div>
            <button onClick={() => { SendMessage() }}>Send</button>
          </div>


        </TabPanel>

      </Tabs>

      <div>


      </div>

    </div>
  );
}

export default Site;
