import React, { useEffect, useState } from 'react';
import './weeklyReport.css';
import { useLocation } from 'react-router-dom';
import Header from './header.png';


const WeeklyReport = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const siteId = queryParams.get('siteid');
  const shiftid = queryParams.get('shiftid');
  const userid = queryParams.get('userid');
  const start_date = queryParams.get('start_date');
  const end_date = queryParams.get('end_date');


  const [GeneralData, setGeneralData] = useState();
  const [UserData, setUserData] = useState([]);
  const [SupervisorData, setSupervisorData] = useState([]);
  const [MonitoringData, setMonitoringData] = useState([]);
  const [EquipmentData, setEquipmentData] = useState([]);
  
  const [EquipmentDataMaint, setEquipmentMaint] = useState([]);
  const [OperationData, setOperationData] = useState([]);
  const [ChemicalData, setChemicalData] = useState([]);
  const [ToolboxData, setToolboxData] = useState([]);
  const [Water, setWater] = useState([]);
  const [totalBill, setTotalBill] = useState(0);



  useEffect(() => {

    const FetchSiteUserById = async () => {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'user/ViewSiteUserById.php?userid='+localStorage.getItem('userid'));
      const jsonData = await response.json();
      if (jsonData.error) {
        alert("Something went Wrong1");
      }
      setUserData(jsonData);
    };
    FetchSiteUserById();

    const FetchSiteSupervisor = async () => {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'user/ViewSiteSupervisor.php?siteid=' + siteId);
      const jsonData = await response.json();
      if (jsonData.error) {
        alert("Something went Wrong2");
      }
      console.log(jsonData);
      setSupervisorData(jsonData);
    };
    FetchSiteSupervisor();

    const FetchSiteGeneralData = async () => {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'user/ViewSiteGeneralData.php?siteid=' + siteId);
      const jsonData = await response.json();
      if (jsonData.error) {
        alert("Something went Wrong3");
      }
      setGeneralData(jsonData);
    };
    FetchSiteGeneralData();

 
  

    const FetchSiteMonitoringData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteMonitoringDataRange.php?siteid=${siteId}&start_date=${start_date}&end_date=${end_date}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong4");
      }
      setMonitoringData(data);
    };
    FetchSiteMonitoringData();


    const FetchSiteEquipmentData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteEquipmentDataRange.php?siteid=${siteId}&start_date=${start_date}&end_date=${end_date}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong5");
      }
      setEquipmentData(data);
    };
    FetchSiteEquipmentData();

    const FetchSiteEquipmentMaint = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteEquipmentMaintenanceRange.php?siteid=${siteId}&start_date=${start_date}&end_date=${end_date}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setEquipmentMaint(data);
    };
    FetchSiteEquipmentMaint();

    const FetchSiteOperationData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteOperationDataRange.php?siteid=${siteId}&date=${start_date}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong6");
      }
      setOperationData(data);
    };
    FetchSiteOperationData();



    const FetchSiteChemicalData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteChemicalDataRange.php?siteid=${siteId}&start_date=${start_date}&end_date=${end_date}`); // Replace with your API URL
      const data = await response.json();
      if(data.length>0){
        if (data.error) {
          alert("Something went Wrong8");
        }
      }
      setChemicalData(data);
      console.log(data);
    };
    FetchSiteChemicalData();


    const FetchSiteToolboxData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteToolboxDataRange.php?siteid=${siteId}&start_date=${start_date}&end_date=${end_date}`); // Replace with your API URL
      const data = await response.json();
      if(data.length>0){
        if (data.error) {
          alert("Something went Wrong8");
        }
      }
      setToolboxData(data);
    };

    FetchSiteToolboxData();

    const FetchSiteWaterData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteWaterDataRange.php?siteid=${siteId}&start_date=${start_date}&end_date=${end_date}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong9");
      }
      setWater(data);
    };

    FetchSiteWaterData();


  }, [siteId, shiftid,start_date, end_date, userid])



  function calculateBill(hours, unit, power) {
    // Define the variables
    const powerInKw = power; // Power of the machine in kW
    const pricePerUnit = unit; // Price per unit in ₹

    // Calculate total energy consumed in kWh
    const energyConsumed = powerInKw * hours; // in kWh

    // Calculate the bill
    const totalBill =(energyConsumed * pricePerUnit).toFixed(2);

    // Output the total bill
    return totalBill;

  }
  

  function calculateTotalPrice(quantityStr, priceStr, conversionFactor = 1) {
    // Extract numeric value and unit from quantity string (e.g., "10kg")
    const quantity = parseFloat(quantityStr);
    
    if(quantityStr){
      const quantityUnit = quantityStr.replace(/[0-9.]/g, '').trim().toLowerCase();
  

    // Extract numeric value and unit from price string (e.g., "₹20/L")
    const pricePerUnit = parseFloat(priceStr.replace(/[₹]/g, '').trim());
    const priceUnit = priceStr.replace(/[0-9₹./]/g, '').trim().toLowerCase();

    // Convert quantity if the units are different
    let convertedQuantity = quantity;
    if (quantityUnit !== priceUnit) {
      if (quantityUnit === 'kg' && priceUnit === 'l') {
        convertedQuantity = quantity * conversionFactor;  // kg to liters
      } else if (quantityUnit === 'l' && priceUnit === 'kg') {
        convertedQuantity = quantity / conversionFactor;  // liters to kg
      } else {
        return 'Invalid units. Please use "kg" or "l".';
      }
    }

    // Calculate total price
    const totalPrice = convertedQuantity * pricePerUnit;

    

    // setTotalBill(totalPrice);

    return totalPrice;
  }
  }


  useEffect(() => {
    // Get all divs with className "cal"

    setTimeout(() => {
      const divs = document.querySelectorAll(".cal");

      // Calculate the total of the numeric content in each div
      let sum = 0;
      divs.forEach((div) => {
        const text = div.textContent;
        const number = parseInt(text.replace(/[^0-9]/g, ""), 10);
        console.log("asa", text);
        if (!isNaN(number)) {
          sum += number;
        }
      });


      setTotalBill(sum); // Set the total
    }, 1000);

  }, []);


  function removeElement(event) {
    const divElement = event.target.closest('.removable');  // Find the closest div with the class .removable
    if (divElement) {
      divElement.remove(); // Remove the entire div
    }           // Remove the element from the DOM
  }



  return (
    <div className="report-container">

      <img alt='img' width="100%" src={Header} style={{ borderBottom: '1px solid black', paddingBottom: '15px' }} />
      <p><span><strong>Site Name:</strong> {GeneralData ? GeneralData.sitename : null} </span> <span><strong>Address:</strong> {GeneralData ? GeneralData.address : null}</span> <span><strong>Contact:</strong> {GeneralData ? GeneralData.contact : null}</span></p>
      <p style={{ borderBottom: '1px solid black', paddingBottom: '15px' }}><span><strong>Date:</strong> {start_date} - {end_date}</span></p>
      {/* {totalBill} */}
      <h2>TANK STATUS</h2>
      <table>
        <thead>
          <tr>
            <th>Operations</th>
            <th>Avg. Tank Levels(in %)</th>
            <th>Health</th>
          </tr>
        </thead>
        <tbody>

          {MonitoringData.length > 0 ? (
            <>
              {MonitoringData.map((monitoring,key) => (

                <tr key={key} className="removable" onDoubleClick={(e) => removeElement(e)}>
                  <td>{monitoring.monitoringname}</td>
                  <td>{monitoring.tanklevel ? Math.floor(monitoring.tanklevel)+"%" : "Not Filled"}</td>
                  <td><textarea className='weeklyinput' type="text"/></td>
                </tr>

              ))}
            </>
          ) : null}
        </tbody>
      </table>

      <h2>EQUIPMENTS</h2>
      <table>
        <thead>
          <tr>
            <th>Equipments</th>
            <th>Op. Cost</th>
            <th>Remark</th>
          </tr>
        </thead>
        <tbody>


          {EquipmentData.length > 0 ? (
            <>
              {
                EquipmentData.map((equipment,key) => (

                  <tr key={key} className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td>{equipment.equipmentname}</td>
                    <td>₹<span className="cal">{calculateBill(equipment.total_duration, equipment.rupees, equipment.power)}</span></td>
                    <td><textarea className='weeklyinput' type="text"/></td>
                  </tr>

                ))
              }
            </>
          ) : null}

        </tbody>
      </table>

      <h2>OPERATIONS</h2>
      <table>
        <thead>
          <tr>
            <th>Sr.No</th>
            <th>Remark</th>
          </tr>
        </thead>
        <tbody>

          {OperationData.length > 0 ? (
            <>
              {
                OperationData.map((operation,key) => (

                  <tr key={key} className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td>{operation.operationname}</td>
                    <td><textarea className='weeklyinput' type="text"/></td>
                  </tr>

                ))
              }
            </>
          ) : null}


        </tbody>
      </table>
<div className='printsection'>
<div>
<h2>STOCKS</h2>
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {ChemicalData.length > 0 ? (
            <>
              {ChemicalData.map((chemical,key) => (

                <tr key={key} className="removable" onDoubleClick={(e) => removeElement(e)}>
                  <td>CHEMICALS</td>
                  <td>{chemical.chemicalname}</td>
                  <td>{chemical.total_quantity}{chemical.type}</td>
                </tr>

              ))}
            </>
          ) : null}


          {ToolboxData.length > 0 ? (
            <>
              {ToolboxData.map((toolbox,key) => (
 
                <tr key={key} className="removable" onDoubleClick={(e) => removeElement(e)}>
                  <td>TOOL BOX</td>
                  <td>{toolbox.name}</td>
                  <td>{toolbox.quantity}</td>
                </tr>

              ))}
            </>
          ) : null}



        </tbody>
      </table>

</div>
<div>
<h2>WATER READING</h2>
      <table>
        <thead>
          <tr>
            <th>Raw Water Flow Meter</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>

          {Water.length > 0 ? (
            <>
              {
                Water.map((parameter,key) => (

                  <tr key={key} className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td>{parameter.name}</td>
                    <td>{parameter.level ? parameter.level + "L" : "Not Filled"}</td>
                  </tr>

                ))
              }
            </>
          ) : null}

        </tbody>
      </table>
</div>
<div>
<h2>WATER PARAMETER</h2>
      <table>
        <thead>
          <tr>
            <th>Treated Water Parameter</th>
            <th>STD.</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>

        <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={"BOD"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"mq/lit"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"mq/lit"}/></td>
                  </tr>
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={"COD"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"mq/lit"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"mq/lit"}/></td>
                  </tr>
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={"TSS"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"mq/lit"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"mq/lit"}/></td>
                  </tr>
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={"PH"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"mq/lit"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"mq/lit"}/></td>
                  </tr>

                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={"TDS"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"mq/lit"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"mq/lit"}/></td>
                  </tr>

                  
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={"MLSS"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                  </tr>
                   
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={""}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                  </tr>
                   
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={""}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                  </tr>
                   
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={""}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                  </tr>
                   
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={""}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                  </tr>

        </tbody>
      </table>
</div>
</div>
   
   


<h2>Note</h2>
      <textarea className='note'></textarea>


      <div className='imgbox'>


        {MonitoringData.length > 0 ? (
          <>
            {MonitoringData.map((data,key) => (
              <div  key={key}>
                {data.img === "true" ? <div className='box removable' onDoubleClick={(e) => removeElement(e)}>
                  <div className='boxtitle'>{data.monitoringname}</div>
                  <img src={`data:image/jpg;charset=utf8;base64,${data.imgdata}`} alt='img' />
                </div> : null}
              </div>




            ))}
          </>
        ) : null}


      </div>


      
{EquipmentDataMaint.length > 0 ? (
            <>
<h2>Maintenance</h2>
<div className='imgbox'>
              {
                EquipmentDataMaint.map((equipment) => (

             
                <div className='box'>
                     <div className='boxtitle'>{equipment.equipmentname}</div>
                  <img src={`data:image/jpg;charset=utf8;base64,${equipment.statusimg}`} alt='img' />
                </div>
                ))
              }
              
      </div>
            </>
          ) : null}


      <img style={{ "width": "100px", "marginTop": "50px" }} src={SupervisorData.sign ? "data:image/jpg;charset=utf8;base64," + SupervisorData.sign : null} alt='sign' />
      <p><strong>Supervisor's Signature</strong></p>
      <p style={{
    display: 'flex',
    justifyContent: 'end',
    marginTop: '-15px',
    marginBottom: '40px',
  }}><strong>Authorized's Signature</strong></p>



      <button onClick={(e) => { e.currentTarget.style.display = 'none'; window.print() }} id='print'>Print</button>
    </div>
  );
}

export default WeeklyReport;
