import React, { useEffect, useState } from 'react';
import './dailyReport.css';
import { useLocation } from 'react-router-dom';
import Header from './header.png';
import Chart from "react-apexcharts";


const DailyReport = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const siteId = queryParams.get('siteid');
  const supervisor = queryParams.get('supervisor');
  const shiftid = queryParams.get('shiftid');
  const userid = queryParams.get('userid');
  const reportdate = queryParams.get('date');


  const [GeneralData, setGeneralData] = useState();
  const [UserData, setUserData] = useState([]);
  const [SupervisorData, setSupervisorData] = useState([]);
  const [ShiftDataById, setShiftDataById] = useState([]);
  const [MonitoringData, setMonitoringData] = useState([]);
  const [EquipmentData, setEquipmentData] = useState([]);
  const [EquipmentDataMaint, setEquipmentMaint] = useState([]);
  const [OperationData, setOperationData] = useState([]);
  const [ChemicalData, setChemicalData] = useState([]);
  const [ToolboxData, setToolboxData] = useState([]);
  const [Water, setWater] = useState([]);
  const [WaterParameter, setWaterParameter] = useState([]);
  const [Nextname, setNextname] = useState("");
  const [Nextcontact, setNextcontact] = useState("");
  const [totalBill, setTotalBill] = useState(0);



  useEffect(() => {

    const FetchSiteGeneralData = async () => {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'user/ViewSiteGeneralData.php?siteid=' + siteId);
      const jsonData = await response.json();
      if (jsonData.error) {
        alert("Something went Wrong");
      }
      setGeneralData(jsonData);
    };
    FetchSiteGeneralData();

    const FetchSiteShiftData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteShiftDataOnlyOperator.php?siteid=${siteId}&date=${reportdate}`);
      const jsonData = await response.json();
      if (jsonData.error) {
        alert("Something went Wrong");
      }
      for (let i = 0; i < jsonData.length; i++) {
        if (jsonData[i].shiftid === shiftid) {
          if (i === jsonData.length - 1) {
            setNextname(jsonData[0].employeename);
          } else {
            setNextname(jsonData[i + 1].employeename);
          }
        }
      }

    };
    FetchSiteShiftData();

    const FetchSiteShiftDataById = async () => {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'user/ViewSiteShiftById.php?shiftid=' + shiftid + '&date=' + reportdate);
      const jsonData = await response.json();
      if (jsonData.error) {
        alert("Something went Wrong");
      }
      setShiftDataById(jsonData);
    };
    FetchSiteShiftDataById();

    const FetchSiteUserById  = async () => {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'user/ViewSiteUserById.php?userid=' + userid);
      const jsonData = await response.json();
      if (jsonData.error) {
        alert("Something went Wrong");
      }
      setUserData(jsonData);
    };
    FetchSiteUserById();

    const FetchSiteSupervisor = async () => {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'user/ViewSiteSupervisor.php?siteid=' + siteId);
      const jsonData = await response.json();
      if (jsonData.error) {
        alert("Something went Wrong");
      }
      console.log(jsonData);
      setSupervisorData(jsonData);
    };
    FetchSiteSupervisor();

    const FetchSiteMonitoringData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteMonitoringData.php?siteid=${siteId}&userid=${userid}&date=${reportdate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setMonitoringData(data);
    };
    FetchSiteMonitoringData();


    const FetchSiteEquipmentData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteEquipmentData.php?siteid=${siteId}&userid=${userid}&date=${reportdate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setEquipmentData(data);
    };
    FetchSiteEquipmentData();

    const FetchSiteEquipmentMaint = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteEquipmentMaintenance.php?siteid=${siteId}&userid=${userid}&date=${reportdate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setEquipmentMaint(data);
    };
    FetchSiteEquipmentMaint();

    const FetchSiteOperationData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteOperationData.php?siteid=${siteId}&userid=${userid}&date=${reportdate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setOperationData(data);
    };
    FetchSiteOperationData();



    const FetchSiteChemicalData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteChemicalData.php?siteid=${siteId}&userid=${userid}&date=${reportdate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setChemicalData(data);
      console.log(data);
    };
    FetchSiteChemicalData();


    const FetchSiteToolboxData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteToolboxData.php?siteid=${siteId}&userid=${userid}&date=${reportdate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setToolboxData(data);
    };

    FetchSiteToolboxData();

    const FetchSiteWaterData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteWaterData.php?siteid=${siteId}&userid=${userid}&date=${reportdate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setWater(data);
    };

    FetchSiteWaterData();

    const FetchSiteWaterParameterData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/ViewSiteWaterParameterData.php?siteid=${siteId}&userid=${userid}&date=${reportdate}`); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }
      setWaterParameter(data);
    };

    FetchSiteWaterParameterData();


  }, [siteId, shiftid, reportdate, userid])



  useEffect(() => {

    const FetchContact = async () => {

      const url = `${process.env.REACT_APP_BASE_URL}user/ViewUsers.php`; // Replace with your actual URL
      const response = await fetch(url); // Replace with your API URL
      const data = await response.json();
      if (data.error) {
        alert("Something went Wrong");
      }

      for (let i = 0; i < data.length; i++) {
        if (data[i].name === Nextname) {
          setNextcontact(data[i].mobile);
        }
      }

    };

    FetchContact();

  }, [Nextname])


  
  function calculateEmployeeBill(start, end, cost) {
    // Define the variables

    // Define the start and end time
    const startTime = new Date(start);
    const endTime = new Date(end);

    // Calculate the duration in hours
    const durationInMs = endTime - startTime; // Duration in milliseconds
    const durationInHours = durationInMs / (1000 * 60 * 60); // Convert milliseconds to hours

    // Calculate total energy consumed in kWh
    const totalBill = cost * durationInHours; // in kWh
    // Output the total bill
    return totalBill;

  }


  function calculateBill(hours, unit, power) {
    // Define the variables
    const powerInKw = power; // Power of the machine in kW
    const pricePerUnit = unit; // Price per unit in ₹

    // Calculate total energy consumed in kWh
    const energyConsumed = powerInKw * hours; // in kWh

    // Calculate the bill
    const totalBill = (energyConsumed * pricePerUnit).toFixed(2); // in ₹

    // Output the total bill
    return totalBill;

  }

  function calculateTotalPrice(quantityStr, priceStr, conversionFactor = 1) {
    // Extract numeric value and unit from quantity string (e.g., "10kg")
    const quantity = parseFloat(quantityStr);
    
    if(quantityStr){
      const quantityUnit = quantityStr.replace(/[0-9.]/g, '').trim().toLowerCase();
  

    // Extract numeric value and unit from price string (e.g., "₹20/L")
    const pricePerUnit = parseFloat(priceStr.replace(/[₹]/g, '').trim());
    const priceUnit = priceStr.replace(/[0-9₹./]/g, '').trim().toLowerCase();

    // Convert quantity if the units are different
    let convertedQuantity = quantity;
    if (quantityUnit !== priceUnit) {
      if (quantityUnit === 'kg' && priceUnit === 'l') {
        convertedQuantity = quantity * conversionFactor;  // kg to liters
      } else if (quantityUnit === 'l' && priceUnit === 'kg') {
        convertedQuantity = quantity / conversionFactor;  // liters to kg
      } else {
        return 'Invalid units. Please use "kg" or "l".';
      }
    }

    // Calculate total price
    const totalPrice = convertedQuantity * pricePerUnit;

    

    // setTotalBill(totalPrice);

    return totalPrice;
  }
  }


  useEffect(() => {
    // Get all divs with className "cal"

    setTimeout(() => {
      const divs = document.querySelectorAll(".cal");

      // Calculate the total of the numeric content in each div
      let sum = 0;
      divs.forEach((div) => {
        const text = div.textContent;
        const number = parseInt(text.replace(/[^0-9]/g, ""), 10);
        console.log("asa", text);
        if (!isNaN(number)) {
          sum += number;
        }
      });


      setTotalBill(sum); // Set the total
    }, 1000);

  }, []);


  // useEffect(() => {
  //   // Select all div elements with the class 'cal'
  //   const divs = document.querySelectorAll(".cal");

  //   // Extract the inner text, convert to numbers, and sum them up
  //   const totalSum = Array.from(divs).reduce((acc, div) => {
  //     const num = parseFloat(div.innerText); // Convert innerText to number
  //     return acc + (isNaN(num) ? 0 : num); // Add only if it's a number
  //   }, 0);

  //   setTotalBill(totalSum); // Set the calculated total in the state
  // }, []);


  const data = {
    options: {
      chart: {
        id: "basic-bar"
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%', // Adjust this value to reduce/increase bar width
          endingShape: 'rounded',
          dataLabels: {
            position: 'top', // Position data labels above the bars
          },
        },
      },
      colors: ['#ebbf03', '#00a6ff'],
      xaxis: {
        categories: ['Standard', 'Calculated']
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `₹${val}`; // Adding the Rupee sign before the value
        },
      },

      annotations: {
        yaxis: [
          {
            y: GeneralData ? GeneralData.standart : 0, // Set the y-value dynamically based on your data
            borderColor: (GeneralData? GeneralData.standart:0) > (totalBill? totalBill / Water[1].level:0) ? '#ffa500' :  '#FF4560', // Color of the limit line
            label: {
              borderColor: (GeneralData? GeneralData.standart:0) > (totalBill? totalBill / Water[1].level:0) ? '#ffa500' :  '#FF4560',
              style: {
                color: '#fff',
                background: (GeneralData? GeneralData.standart:0) > (totalBill? totalBill / Water[1].level:0) ? '#ffa500' :  '#FF4560',
              },
              text: 'BenchMark', // Label text for the limit line
            },
          },
        ],
      },

    },
    series: [
      {
        name: "Water Treated Cost Per Liter",
        data: [
          { x: 'Standard', y: GeneralData? GeneralData.standart:0, fillColor: '#008FFB' }, // Red color for Category 1
          { x: 'Calculated', y: totalBill? totalBill / Water[1].level:0, fillColor: (GeneralData? GeneralData.standart:0) > (totalBill? totalBill / Water[1].level:0) ? '#ffa500' :  '#FF4560'}, // Blue color for Category 2
        ],
      }
    ],
  };

  function removeElement(event) {
    const divElement = event.target.closest('.removable');  // Find the closest div with the class .removable
    if (divElement) {
      divElement.remove(); // Remove the entire div
    }           // Remove the element from the DOM
  }

  return (
    <div className="report-container">

      <img style={{ borderBottom: '1px solid black', paddingBottom: '5px' }} alt='img' width="100%" src={Header} />
      <p><span><strong>Site Name:</strong> {GeneralData ? GeneralData.sitename : null} </span> <span><strong>Address:</strong> {GeneralData ? GeneralData.address : null}</span> <span><strong>Date:</strong> {reportdate}</span></p>
      <p style={{ borderBottom: '1px solid black', paddingBottom: '15px' }}><span><strong>Operator:</strong>{ShiftDataById ? ShiftDataById.employeename : null}({GeneralData ? GeneralData.contact : null}) </span> <span><strong>Handover:</strong> {Nextname}({Nextcontact})</span> <span><strong>Shift:</strong> {ShiftDataById ? ShiftDataById.shifttype : null}</span></p>
      {/* {totalBill} */}

      <h2 className="removable" onDoubleClick={(e) => removeElement(e)}>EQUIPMENTS</h2>
      <table>
        <thead className="removable" onDoubleClick={(e) => removeElement(e)}>
          <tr>
            <th>Equipments</th>
            <th>Make</th>
            <th>Model</th>
            <th>Power</th>
            <th>Status</th>
            <th>Op. Time</th>
            <th>Op. Cost</th>
          </tr>
        </thead>
        <tbody>


          {EquipmentData.length > 0 ? (
            <>
              {
                EquipmentData.map((equipment) => (

                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td>{equipment.equipmentname}</td>
                    <td>{equipment.make}</td>
                    <td>{equipment.model}</td>
                    <td>{equipment.power} kW</td>
                    <td>{equipment.status ? equipment.status : "Not Filled"}</td>
                    <td>{equipment.hours} Hours</td>
                    <td>₹<span className="cal">{equipment.status!=='Off'?calculateBill(equipment.hours, equipment.rupees, equipment.power):0}</span></td>
                  </tr>

                ))
              }
            </>
          ) : null}

        </tbody>
      </table>

 

<div className='printsection'>
<div className='printsectionl'>
<h2 className="removable" onDoubleClick={(e) => removeElement(e)}>TANK STATUS</h2>
      <table>
        <thead className="removable" onDoubleClick={(e) => removeElement(e)}>
          <tr>
            <th>Operations</th>
            <th>Tank Level(%)</th>
          </tr>
        </thead>
        <tbody>

          {MonitoringData.length > 0 ? (
            <>
              {MonitoringData.map((monitoring) => (

                <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                  <td>{monitoring.monitoringname}</td>
                  <td>{monitoring.tanklevel ? Math.floor(monitoring.tanklevel)+"%" : "Not Filled"}</td>
                </tr>

              ))}
            </>
          ) : null}
        </tbody>
      </table>
</div>

<div className='printsectionm'>
<h2 className="removable" onDoubleClick={(e) => removeElement(e)}>WATER READING</h2>
      <table>
        <thead>
          <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
            <th>Water Flow Meter</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>

          {Water.length > 0 ? (
            <>
              {
                Water.map((parameter) => (

                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td>{parameter.name}</td>
                    <td>{parameter.level ? parameter.level + "L" : "Not Filled"}</td>
                  </tr>

                ))
              }
            </>
          ) : null}

        </tbody>
      </table>
</div>
<div className='printsectionr'>
  
<h2 className="removable" onDoubleClick={(e) => removeElement(e)}>STOCKS</h2>
      <table>
        <thead>
          <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
            <th>Items</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {ChemicalData.length > 0 ? (
            <>
              {ChemicalData.map((chemical) => (

                <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                  <td>{chemical.chemicalname}</td>
                  <td>{chemical.quantity}{chemical.type}</td>
               </tr>

              ))}
            </>
          ) : null}


          {ToolboxData.length > 0 ? (
            <>
              {ToolboxData.map((toolbox) => (

                <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                  <td>{toolbox.name}</td>
                  <td>{toolbox.quantity}</td>
                </tr>

              ))}
            </>
          ) : null}



        </tbody>
      </table>
</div>
</div>
    
<h2 className="removable" onDoubleClick={(e) => removeElement(e)}>OPERATIONS</h2>
<table>
  <thead>
    <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
    <th>Name</th>
    <th>Operations</th>
      <th>Start Time</th>
      <th>End Time</th>
    </tr>
  </thead>
  <tbody>
  {OperationData.length > 0 ? (
  OperationData.map((operation, index) => {
    // Parse the JSON field into a JavaScript object or fallback to {}
    const operationJson = operation.json ? JSON.parse(operation.json) : {};

    // Create an array of operation types (e.g., Backwash, Rinse, etc.)
    const operationTypes = Object.keys(operationJson);

    // If operationTypes is empty, fallback to a single row with "Not Filled"
    return operationTypes.length > 0
      ? operationTypes.map((type, typeIndex) => (
          <tr key={`${index}-${typeIndex}`}>
            {/* Display the operation name only once for each operation */}
            {typeIndex === 0 && (
              <td rowSpan={operationTypes.length} className="removable" onDoubleClick={(e) => removeElement(e)}>
                {index + 1}. {operation.operationname}
              </td>
            )}

            <td className="removable" onDoubleClick={(e) => removeElement(e)}>{type}</td>
            <td className="removable" onDoubleClick={(e) => removeElement(e)}>
              Start Time: {operationJson[type]?.start || "Not Filled"}
            </td>
            <td className="removable" onDoubleClick={(e) => removeElement(e)}>
              End Time: {operationJson[type]?.end || "Not Filled"}
            </td>
          </tr>
        ))
      : (
        <tr key={index}>
          <td className="removable" onDoubleClick={(e) => removeElement(e)}>
            {index + 1}. {operation.operationname}
          </td>
          <td className="removable" onDoubleClick={(e) => removeElement(e)}>Not filled</td>
          <td className="removable" onDoubleClick={(e) => removeElement(e)}>Start Time: Not Filled</td>
          <td className="removable" onDoubleClick={(e) => removeElement(e)}>End Time: Not Filled</td>
        </tr>
      );
  })
) : (
  <tr>
    <td colSpan="4">No operations data available</td>
  </tr>
)}

  </tbody>
</table>

<h2 className="removable" onDoubleClick={(e) => removeElement(e)}>WATER PARAMETER</h2>
      <table>
        <thead>
          <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
            <th>Treated Water Parameter</th>
            <th>STD.</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>


        {WaterParameter[0] ?

<>



  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' defaultValue={WaterParameter[0].name1}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].std1} type="text"/> <input type='text' defaultValue={WaterParameter[0].stdunit1}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].value1} type="text"/> <input type='text' defaultValue={WaterParameter[0].valueunit1}/></td>
                  </tr>

                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' defaultValue={WaterParameter[0].name2}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].std2} type="text"/> <input type='text' defaultValue={WaterParameter[0].stdunit2}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].value2} type="text"/> <input type='text' defaultValue={WaterParameter[0].valueunit2}/></td>
                  </tr>

                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' defaultValue={WaterParameter[0].name3}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].std3} type="text"/> <input type='text' defaultValue={WaterParameter[0].stdunit3}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].value3} type="text"/> <input type='text' defaultValue={WaterParameter[0].valueunit3}/></td>
                  </tr>

                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' defaultValue={WaterParameter[0].name4}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].std4} type="text"/> <input type='text' defaultValue={WaterParameter[0].stdunit4}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].value4} type="text"/> <input type='text' defaultValue={WaterParameter[0].valueunit4}/></td>
                  </tr>
                  
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' defaultValue={WaterParameter[0].name5}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].std5} type="text"/> <input type='text' defaultValue={WaterParameter[0].stdunit5}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].value5} type="text"/> <input type='text' defaultValue={WaterParameter[0].valueunit5}/></td>
                  </tr>

                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' defaultValue={WaterParameter[0].name6}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].std6} type="text"/> <input type='text' defaultValue={WaterParameter[0].stdunit6}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].value6} type="text"/> <input type='text' defaultValue={WaterParameter[0].valueunit6}/></td>
                  </tr>

                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' defaultValue={WaterParameter[0].name7}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].std7} type="text"/> <input type='text' defaultValue={WaterParameter[0].stdunit7}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].value7} type="text"/> <input type='text' defaultValue={WaterParameter[0].valueunit7}/></td>
                  </tr>

                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' defaultValue={WaterParameter[0].name8}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].std8} type="text"/> <input type='text' defaultValue={WaterParameter[0].stdunit8}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].value8} type="text"/> <input type='text' defaultValue={WaterParameter[0].valueunit8}/></td>
                  </tr>

                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' defaultValue={WaterParameter[0].name9}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].std9} type="text"/> <input type='text' defaultValue={WaterParameter[0].stdunit9}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].value9} type="text"/> <input type='text' defaultValue={WaterParameter[0].valueunit9}/></td>
                  </tr>

                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' defaultValue={WaterParameter[0].name10}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].std10} type="text"/> <input type='text' defaultValue={WaterParameter[0].stdunit10}/></td>
                    <td><textarea className='weeklyinput' defaultValue={WaterParameter[0].value10} type="text"/> <input type='text' defaultValue={WaterParameter[0].valueunit10}/></td>
                  </tr>


</>

:
<>

<tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={"BOD"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input type='text' value={"mq/lit"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input type='text' value={"mq/lit"}/></td>
                  </tr>
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={"COD"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input type='text' value={"mq/lit"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input type='text' value={"mq/lit"}/></td>
                  </tr>
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={"TSS"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input type='text' value={"mq/lit"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input type='text' value={"mq/lit"}/></td>
                  </tr>
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={"PH"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input type='text' value={"mq/lit"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input type='text' value={"mq/lit"}/></td>
                  </tr>

                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={"TDS"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input type='text' value={"mq/lit"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input type='text' value={"mq/lit"}/></td>
                  </tr>

                  
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={"MLSS"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input type='text' value={"%"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input type='text' value={"%"}/></td>
                  </tr>

                  
                   
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={""}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                  </tr>
                   
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={""}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                  </tr>
                   
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={""}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                  </tr>
                   
                  <tr className="removable" onDoubleClick={(e) => removeElement(e)}>
                    <td><input type='text' value={""}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                    <td><textarea className='weeklyinput' type="text"/> <input style={{ "width": "50px" }} type='text' value={"%"}/></td>
                  </tr>

</>
}


        </tbody>
      </table>


      <div className='imgbox'>


        {MonitoringData.length > 0 ? (
          <>
            {MonitoringData.map((data) => (
              <>
                {data.img === "true" ? <div className='box'>
                  <div className='boxtitle'>{data.monitoringname}</div>
                  <img src={`data:image/jpg;charset=utf8;base64,${data.imgdata}`} alt='img' />
                </div> : null}
              </>




            ))}
          </>
        ) : null}


      </div>



      {EquipmentDataMaint.length > 0 ? (
            <>
<h2>Maintenance</h2>
<div className='imgbox'>
              {
                EquipmentDataMaint.map((equipment) => (

             
                <div className='box'>
                     <div className='boxtitle'>{equipment.equipmentname}</div>
                  <img src={`data:image/jpg;charset=utf8;base64,${equipment.statusimg}`} alt='img' />
                </div>
                ))
              }
              
      </div>
            </>
          ) : null}


      <h2>Note</h2>
      <textarea className='note'></textarea>

      <br/>

      <img style={{ "width": "100px", "marginTop": "50px" }} src={UserData ? "data:image/jpg;charset=utf8;base64," + SupervisorData.sign : null} alt='sign' />
      <p><strong>Supervisor's Signature</strong></p>
      <p style={{
    display: 'flex',
    justifyContent: 'end',
    marginTop: '-15px',
    marginBottom: '40px',
  }}><strong>Authorized's Signature</strong></p>



      <Chart
        options={data.options}
        series={data.series}
        type="bar"
        width="500"
      />

      <button onClick={(e) => { e.currentTarget.style.display = 'none'; window.print() }} id='print'>Print</button>
    </div>
  );
}

export default DailyReport;
